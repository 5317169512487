import React, {useState, useEffect} from 'react';
import axios from "axios";


function Articles() {
    const [posts, setPosts] = useState([]);
  const getPostData = () => {
    axios
      .get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@kiransavaria")
      .then((res) => {
        setPosts(res.data.items);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  };
  useEffect(() => {
    getPostData();
  }, []);
  return (
    <section className='project-section padding-50'>
    <h1 style={{fontSize:'56px',lineHeight:'67.2px',textAlign:'left',marginBottom:'35px'}}>
        Articles<span className='custom-dot'>.</span>
    </h1>
        <div class="row row-cols-1 row-cols-md-2 g-4">
        {posts.map((post) => (
            <div key={post.guid} class="col">
                <div class="card" style={{textAlign:'left'}}>
                {post.thumbnail&&<img src={post.thumbnail} style={{margin:'0 1rem'}} class="img-thumbnail" alt="..." />}
                <div class="card-body">
                    <h5 class="card-title">{post.title}</h5>
                    <p>Published on: <span>{new Date(post.pubDate).toDateString()}</span></p>
                    <a href={post.link} target="_blank" rel="noopener noreferrer">
                    Read more
                    </a>
                </div>
               
                </div>
            </div>
            
        ))}
            </div>
    </section>
  )
}

export default Articles