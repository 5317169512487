import React from 'react'
import project1 from '././project1.png';
import project2 from '././project2.png';
import project3 from '././project3.png';
import project4 from '././project4.png';

function Projects() {
  return (
    <section id='projects' className='project-section padding-50'>
        <h1 style={{fontSize:'56px',lineHeight:'67.2px',textAlign:'left',marginBottom:'35px'}}>
            React Projects<span className='custom-dot'>.</span>
        </h1>
        <div className='col-sm-12 row'>
            <div className='col-sm-5'>
                <div class="card mb-3">
                    <img src={project1} style={{margin:'0 1rem'}} class="img-thumbnail" alt="..." />
                    <div class="card-body">
                        <h5 class="card-title">Event Count Down
                            <a style={{paddingLeft:'0.5rem'}} className='icons-a-tag' href='/counter'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-circle" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"/>
                            </svg>
                            </a>
                        </h5>
                        <p class="card-text">Engaging Event Countdown Page for New Year 2024</p>
                        
                    </div>
                </div>
            </div>
            <div className='col-sm-7'>
                <div class="card mb-3">
                    <img src={project2} style={{margin:'0 1rem'}} class="img-thumbnail" alt="..." />
                    <div class="card-body">
                    <h5 class="card-title">Random Password Generator 
                            <a style={{paddingLeft:'0.5rem'}} className='icons-a-tag' href='/generatepassword'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-circle" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"/>
                            </svg>
                            </a>
                        </h5>
                        <p class="card-text">Random Password Generator using the generate-password npm Library</p>
                        
                    </div>
                </div>
            </div>
        </div>
        <div className='col-sm-12 row'>
            <div className='col-sm-7'>
                <div class="card mb-3">
                    <img src={project3} style={{margin:'0 1rem'}} class="img-thumbnail" alt="..." />
                    <div class="card-body">
                    <h5 class="card-title">Country Flags
                            <a style={{paddingLeft:'0.5rem'}} className='icons-a-tag' href='/countryflags'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-circle" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"/>
                            </svg>
                            </a>
                        </h5>
                        <p class="card-text">Display Country flags API</p>
                        
                    </div>
                </div>
            </div>
            <div className='col-sm-5'>
                <div class="card mb-3">
                    <img src={project4} style={{margin:'0 1rem'}} class="img-thumbnail" alt="..." />
                    <div class="card-body">
                    <h5 class="card-title">Product card 
                            <a style={{paddingLeft:'0.5rem'}} className='icons-a-tag' href='/productDetail'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-circle" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"/>
                            </svg>
                            </a>
                        </h5>
                        <p class="card-text">Product card using fake api</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Projects