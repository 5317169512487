import React from 'react'

function Mystory() {
  return (
    <section id='about' className='project-section padding-50'>
    <h1 style={{fontSize:'56px',lineHeight:'67.2px',textAlign:'left',marginBottom:'35px'}}>
        My Story<span className='custom-dot'>.</span>
    </h1>
    <p style={{fontSize:'18px'}}>
        Passionate FrontEnd Developer with a Master's from BITS Pilani Hyderabad, specializing in Full Stack Engineering. Proficient in React, Figma, Js, Flutter and UI/UX design, my journey has been enriched with experiences in technical consulting, project leadership, and web development

        As a Master in Frontend Technologies, I have been taken diverse roles as a Technical Consultant, Project Head, and Web Developer, showcasing my versatility in navigating the realms of development and digital marketing. With a track record of success in renowned companies such as YouTube (Accenture) and Amrita Technologies. From technical consulting to project leadership, I have led diverse projects, earning recognition for excellence in SEO and the Best Employee award for impactful work in Development. 

        I've contributed significantly to web development projects and mobile apps. for more than 50+ clients. My passion lies in tackling UI challenges, and I am glad and eager to share my knowledge/expertise and grateful to connect with like-minded professionals to explore new opportunities.

        Besides it I hold a passion in writing quotes. 
        <br/>
        <br/>
        Explore my quotes: https://www.yourquote.in/kiran-savaria-jpsl/quotes.

        
        
        
    </p>
</section>
  )
}

export default Mystory