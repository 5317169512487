import React from 'react';
import './Home.css';
import Hero from './Hero';
import Projects from './Projects';
import Experience from './Experience';
import Skills from './Skills';
import Mystory from './Mystory';
import Footer from '../Footer/Footer';
import Articles from './Articles';
import Designs from './Designs';
import Header from '../Header/Header';

function Home() {
  return (
    <div className='container'>
        <Header />
        <Hero />
        <Experience/>
        <Projects />
        <Articles />
        <Designs />
        <Skills />
        <Mystory/>
        <Footer />
    </div>
  )
}

export default Home