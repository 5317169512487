import React from 'react'

function Designs() {
  return (
    <section id='design' className='project-section padding-50'>
        <h1 style={{fontSize:'56px',lineHeight:'67.2px',textAlign:'left',marginBottom:'35px'}}>
        Designs<span className='custom-dot'>.</span>
        </h1>
        <div className='row'>
            <div className='col-sm-4'>
                <iframe src="https://www.behance.net/embed/project/189819117?ilo0=1" height="316" width="350" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            </div>

            <div className='col-sm-4'>
            <iframe src="https://www.behance.net/embed/project/189816869?ilo0=1" height="316" width="350" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            </div>  

            <div className='col-sm-4'>
                <iframe src="https://www.behance.net/embed/project/189813871?ilo0=1" height="316" width="350" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            </div>

           

            <div className='col-sm-4'>
            <iframe src="https://www.behance.net/embed/project/189817847?ilo0=1" height="316" width="350" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            </div>

            <div className='col-sm-4'>
            <iframe src="https://www.behance.net/embed/project/189818049?ilo0=1" height="316" width="350" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            </div>  

            <div className='col-sm-4'>
            <iframe src="https://www.behance.net/embed/project/189816519?ilo0=1" height="316" width="350" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            </div>
            
        </div>
    </section>
  )
}

export default Designs