import React from 'react'

function Skills() {
  return (
    <section style={{textAlign:'left'}} className='skills-section padding-50'>
    <h1 style={{fontSize:'56px',lineHeight:'67.2px',textAlign:'left',marginBottom:'35px'}}>
        Skills<span className='custom-dot'>.</span>
    </h1>
    <div className='col-sm-12 row'>
        <div className='col-sm-3'>
            <h4>Web Design</h4>
            <ul>
                <li>Adobe XD</li>
                <li>Figma</li>
                <li>UX Design</li>
                <li>UI Design</li>
                <li>Responsive Design</li>
            </ul>
        </div>
        <div className='col-sm-3'>
        <h4>Frontend</h4>
            <ul>
                <li>React JS</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>Javascript</li>
                <li>Bootstrap</li>
            </ul>
        </div>
        <div className='col-sm-3'>
        <h4>CMS</h4>
            <ul>
                <li>Wordpress</li>
                <li>Shopify</li>
                <li>Blogger</li>
                <li>Wix</li>
                <li>Webflow</li>
            </ul>
        </div>
        <div className='col-sm-3'>
        <h4>Other Skills</h4>
            <ul>
                <li>MySQL</li>
                <li>GIT</li>
                <li>Microservices</li>
                <li>Docker</li>
                <li>Kubernetes</li>
            </ul>
        </div>
    </div>
    
</section>
  )
}

export default Skills