import React from 'react'

function Experience() {
  return (
    <section className='experience-section padding-50'>
    <h1 style={{textAlign:'left',marginBottom:'35px'}}>
        Experience<span className='custom-dot'>.</span>
    </h1>
    <div class="container d-flex align-items-start experience-section-div">
        <ul class="nav nav-pills flex-column nav-pills border-start border-3 me-3 align-items-start" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
            <button class="nav-link text-primary fw-semibold active position-relative" id="pills-one-tab" data-bs-toggle="pill" data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one" aria-selected="true">Instrukart Holdings</button>
            </li>
            <li class="nav-item" role="presentation">
            <button class="nav-link text-primary fw-semibold position-relative" id="pills-two-tab" data-bs-toggle="pill" data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two" aria-selected="false">Pride Web Technologies</button>
            </li>
            <li class="nav-item" role="presentation">
            <button class="nav-link text-primary fw-semibold position-relative" id="pills-three-tab" data-bs-toggle="pill" data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three" aria-selected="false">Pharoscion Global</button>
            </li>
            <li class="nav-item" role="presentation">
            <button class="nav-link text-primary fw-semibold position-relative" id="pills-four-tab" data-bs-toggle="pill" data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four" aria-selected="false">Accenture</button>
            </li>
        </ul>
        <div class="tab-content p-3 w-100" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab">
            <h2>Technical Consultant 
             <span className='custom-dot'> @ Instrukart Holdings</span>
                <br/>
                
            </h2>
            <p>March 2023 - September 2023</p>
            <p>Handled front-end deliverables, site optimization, server configuration, software integration for different IOT devices.
               <br/> <br/> <span className='custom-dot'>Skills:</span> JavaScript · Cascading Style Sheets (CSS) · MySQL </p>
            </div>
            <div class="tab-pane fade" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab">
            <h2>Project Head
            <span className='custom-dot'> @ Pride Web Technologies</span>
                <br/>
                
            </h2>
            <p>December 2021 - January 2023</p>
            <p>Worked on several client websites be it in any industry. Learnt about the Digital Marketing trends and processes in-depth. 
                Was Responsible to deliver the needful requirements to clients. Involved in project delivery plan. 
               
                
               <br/> <br/> <span className='custom-dot'>Skills:</span> Front-End Development · React.js · Microservices · Search Engine Optimization (SEO) · SEO Audits · WordPress </p>
            </div>
            <div class="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab">
            <h2>Web developer
            <span className='custom-dot'> @ Pharoscion</span>
                <br/>
                
            </h2>
            <p>August 2021 - December 2021</p>
            <p>

            Was part of UI team which involved responsive designing of websites and applications. I've handled Digital Marketing and SEO projects
           <br/> <br/> <span className='custom-dot'>Skills:</span> Front-End Development · JavaScript · Search Engine Optimization (SEO) · WordPress · Amazon Web Services (AWS) 
            </p>
            </div>
            <div class="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab">
            <h2>Business Associate
            <span className='custom-dot'> @ Accenture</span>
                <br/>
                
            </h2>
            <p>March 2019 - August 2021</p>
            <p>
            Worked for YT Client Review Process which involved reporting bugs, testing the Beta platform tool, content review, audit and investigation.
           <br/> <br/> <span className='custom-dot'>Skills:</span> Business Process Improvement · Project Management · User Acceptance Testing
            </p>
            </div>
        </div>
    </div>
    
</section>
  )
}

export default Experience